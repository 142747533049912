import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

//以下だとpruduction環境で反映されない、、(原因未解明)
//なので結局Gemを使うことにした
//import '@fortawesome/fontawesome-free/js/all';
//import '../css/fontawesome.scss'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// アラートメッセージを一定時間で非表示
$(function(){
    setTimeout("$('.alert-message').slideUp('slow')", 4000)
});